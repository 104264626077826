import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import OtherHero from '../components//other_hero/other_hero'
import EmployeeAppSection from '../components//employee_app_section/employee_app_section'
import ProofPoints from '../components/proof_points/proof_points'
import { assetNames } from '../utilities.js/constants'

import './base.css'
import Layout from '../components/website_layout/layout'
// Prevent Gatsby SSR build errors
const isBrowser = typeof window !== 'undefined'

const AboutUs = (props) => {
  const assets = assetNames.reduce((res, assetName) => {
    res[assetName] = props.data[assetName]?.gatsbyImageData
    return res
  }, {})

  useEffect(() => {
    if (isBrowser) {
      // Track seeing this page
      window.analytics.track('website_page_viewed', {
        page: 'about',
      })
    }
  }, [])

  const siteMetadata = props.data.contentfulSiteMetadata
  // Override for page meta and title
  siteMetadata.title = props.data.contentfulAboutPageContent.title
  siteMetadata.description = props.data.contentfulAboutPageContent.description

  return (
    <>
      <Layout
        data={props.data.contentfulAboutPageContent}
        seo={siteMetadata}
        showScrollToTop={true}
        assets={assets}
      >
        <OtherHero
          data={props.data.contentfulAboutPageContent.mainContent}
          showAppLinks={true}
          isImageFull={true}
          assets={assets}
        />
        <EmployeeAppSection
          data={props.data.contentfulAboutPageContent.productListContent}
        />
        <ProofPoints
          data={props.data.contentfulAboutPageContent.proofPointContent}
        />
        <EmployeeAppSection
          data={props.data.contentfulAboutPageContent.productListContent2}
        />
      </Layout>
    </>
  )
}

export default AboutUs
export const pageQuery = graphql`
  query AboutQuery {
    contentfulAboutPageContent(
      contentful_id: { eq: "73mp3kwpAiFJo0all56Zci" }
    ) {
      title
      description
      topNavigationBar {
        navPaths {
          ... on ContentfulDropDown {
            internal {
              type
            }
            name
            linkEntries {
              label
              url
            }
          }
          ... on ContentfulPath {
            internal {
              type
            }
            url
            label
          }
        }
        logolarge {
          file {
            url
          }
        }
      }
      mainContent {
        rightImage {
          gatsbyImageData(width: 800)
          title
        }
        subtitle
        topBubbleText
        title
        fieldPlaceholder
        fieldButton {
          label
          url
        }
        formTitle
      }
      productListContent {
        id
        imageStartsLeft
        productContentItem {
          subtitle
          title
          image {
            gatsbyImageData(width: 640)
            title
          }
          left
          paragraph {
            childMarkdownRemark {
              html
              rawMarkdownBody
              htmlAst
            }
          }
        }
      }
      proofPointContent {
        title
        subtitle
        item {
          title
          titleBold1 {
            childMarkdownRemark {
              html
              rawMarkdownBody
              htmlAst
            }
          }
          subtitle
          paragraph
          image {
            gatsbyImageData(width: 96)
            title
          }
          leftImage {
            gatsbyImageData(width: 80)
            title
          }
          rightImage {
            gatsbyImageData(width: 80)
            title
          }
          link {
            url
            label
          }
        }
        paragraph
      }
      productListContent2 {
        id
        imageStartsLeft
        productContentItem {
          subtitle
          title
          moreInfo {
            label
            url
          }
          image {
            gatsbyImageData(width: 640)
            title
          }
          left
          paragraph {
            childMarkdownRemark {
              html
              rawMarkdownBody
              htmlAst
            }
          }
        }
      }
      ctaSectionContent {
        successMessage
        errorMessage
        subtitle
        title
        placeholderTitle
        placeholderFieldName
        ctaTitle
        image {
          gatsbyImageData(width: 396)
          title
        }
      }
      footerContent {
        section1Title
        section2Title
        section3Title
        title
        section1Paths {
          newTab
          label
          url
          logo {
            gatsbyImageData(width: 30)
            title
          }
        }
        section2Paths {
          newTab
          label
          url
          logo {
            gatsbyImageData(width: 30)
            title
          }
        }

        section3Paths {
          newTab
          label
          url
          logo {
            gatsbyImageData(width: 30)
            title
          }
        }
      }
    }
    googlePlayBadgeBlack: contentfulCtaButton(
      contentful_id: { eq: "q00MfpdmihX36ZSSzO2VS" }
    ) {
      buttonAlt
      buttonImage {
        gatsbyImageData(width: 235)
      }
    }
    appStoreBadgeBlack: contentfulCtaButton(
      contentful_id: { eq: "4JX8m4WjDpVydFskT6GEeu" }
    ) {
      buttonAlt
      buttonImage {
        gatsbyImageData(width: 235)
      }
    }
    googlePlayBadge: contentfulAsset(
      file: { fileName: { eq: "google-store-badge-new.png" } }
    ) {
      gatsbyImageData(width: 235)
    }
    appStoreBadge: contentfulAsset(
      file: { fileName: { eq: "app-store-badge-new.png" } }
    ) {
      gatsbyImageData(width: 235)
    }
    browserBadge: contentfulAsset(
      file: { fileName: { eq: "browser-badge.png" } }
    ) {
      gatsbyImageData(width: 235)
    }
    contentfulSiteMetadata(contentful_id: { eq: "4GzU8oQlPc2YDh3dwyPU1N" }) {
      description
      imageUrl
      siteName
      title
      twitterHandle
      siteUrl
    }
  }
`
