import React from 'react'
import Typography from '../ui/typography'
import Container from '../ui/container'
import AppLinks from '../app_links/app_links'
import * as styles from './other_hero.module.css'
import { GatsbyImage } from 'gatsby-plugin-image'

const { Paragraph } = Typography

export default ({
  data,
  isH1Flip,
  mobileNoShowImage,
  isImageFull,
  assets,
  showAppLinks,
}) => (
  <Container className={styles.heroContainer}>
    <div className={styles.heroTextContainer}>
      <div className={styles.tagContainer}>
        <Paragraph className={styles.tag}>{data.topBubbleText}</Paragraph>
      </div>
      {isH1Flip ? (
        <>
          <Paragraph className={styles.title}>{data.title}</Paragraph>
          <h1 className={styles.subtitle}>{data.subtitle}</h1>
        </>
      ) : (
        <>
          <h1 className={styles.title}>{data.title}</h1>
          <Paragraph className={styles.subtitle}>{data.subtitle}</Paragraph>
        </>
      )}
      {assets && showAppLinks && (
        <div className={styles.appLinks}>
          <AppLinks assets={assets} browserLink={true} isBlack={false} />
        </div>
      )}
    </div>
    <div
      className={
        mobileNoShowImage == undefined
          ? isImageFull
            ? styles.heroImageContainerFull
            : styles.heroImageContainer
          : styles.heroImageContainerHidden
      }
    >
      <GatsbyImage
        alt={data.rightImage.title}
        imgStyle={{
          objectFit: 'contain',
          objectPosition: 'center',
        }}
        className={
          mobileNoShowImage == undefined
            ? isImageFull
              ? styles.imageFull
              : styles.image
            : styles.imageHidden
        }
        image={data.rightImage.gatsbyImageData}
      />
    </div>
  </Container>
)
