// extracted by mini-css-extract-plugin
export var appFeatures = "features-module--appFeatures--6b348";
export var arrowImg = "features-module--arrowImg--125ac";
export var check = "features-module--check--62f9a";
export var content = "features-module--content--7e25e";
export var eAppFeatures = "features-module--eAppFeatures--34b4a";
export var featureContent = "features-module--featureContent--42a6f";
export var featureImg = "features-module--featureImg--9f531";
export var featureImgLeft = "features-module--featureImgLeft--7c947";
export var featureImgRight = "features-module--featureImgRight--768b9";
export var featureLink = "features-module--featureLink--7ef9e";
export var image = "features-module--image--5e858";
export var linkButton = "features-module--linkButton--f6c7d";
export var linkButtonTitle = "features-module--linkButtonTitle--86b0e";
export var listContent = "features-module--listContent--aea88";
export var subtitle = "features-module--subtitle--6709f";
export var title = "features-module--title--ad25a";