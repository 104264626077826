import React from 'react'
import Section from '../ui/section'
import Features from '../features/features'

export default (props) => {
  return (
    <Section className="bg-white flex flex-col">
      <Features employeePage={true} data={props.data} />
    </Section>
  )
}
