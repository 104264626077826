import React from 'react'
import Section from '../ui/section'
import Container from '../ui/container'
import Typography from '../ui/typography'
import { GatsbyImage } from 'gatsby-plugin-image'
import * as styles from './proof_points.module.css'

const { Paragraph } = Typography

const convertTextToBold = (textArray) => {
  var listSpans = []
  var hasSkippedLine = false
  for (let i = 0; i < textArray.length; i++) {
    if (textArray[i].tagName) {
      if (!hasSkippedLine) {
        listSpans.push(
          <br key={`${textArray[i].children[0].value.slice(0, 8)}_br`} />
        )
        hasSkippedLine = true
      }
      listSpans.push(
        <span
          key={textArray[i].children[0].value.slice(0, 8)}
          className="text-3xl"
        >
          {textArray[i].children[0].value}
        </span>
      )
      listSpans.push(<br key={`${i}_${textArray[i].tagName}_br`} />)
    } else {
      listSpans.push(<span key={`${i}_value`}>{textArray[i].value}</span>)
    }
  }

  return listSpans
}

export default (props) => {
  return (
    <Section className="bg-white">
      <Container className={styles.container}>
        <div className="flex-col flex items-center">
          <Paragraph className={styles.tag}>{props.data.subtitle}</Paragraph>
          <h2 className={styles.title}>{props.data.title}</h2>
          <Paragraph className={styles.paragraph}>
            {props.data.paragraph}
          </Paragraph>
        </div>
        <ul className={styles.points}>
          {props.data.item.map((f, idx) => (
            <li key={idx} className={styles.pointsItem}>
              <div className="flex flex-row justify-center items-center gap-4 h-30">
                <GatsbyImage
                  alt={f.leftImage.title}
                  className={styles.laurel}
                  imgStyle={{
                    objectFit: 'contain',
                    objectPosition: 'right',
                  }}
                  image={f.leftImage.gatsbyImageData}
                />
                <div className="flex flex-col justify-center">
                  <Paragraph className={styles.pointsItemTitle}>
                    {convertTextToBold(
                      f.titleBold1.childMarkdownRemark.htmlAst.children[0]
                        .children
                    )}
                  </Paragraph>
                  {f.image && (
                    <GatsbyImage
                      alt={f.image.title}
                      className={styles.pointsItemImage}
                      imgStyle={{
                        objectFit: 'contain',
                        objectPosition: 'center',
                      }}
                      image={f.image.gatsbyImageData}
                    />
                  )}
                </div>
                <GatsbyImage
                  alt={f.rightImage.title}
                  className={styles.laurel}
                  imgStyle={{
                    objectFit: 'contain',
                    objectPosition: 'left',
                  }}
                  image={f.rightImage.gatsbyImageData}
                />
              </div>
            </li>
          ))}
        </ul>
      </Container>
    </Section>
  )
}
