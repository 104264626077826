// extracted by mini-css-extract-plugin
export var heroContainer = "other_hero-module--heroContainer--b9637";
export var heroImageContainer = "other_hero-module--heroImageContainer--f13dd";
export var heroImageContainerFull = "other_hero-module--heroImageContainerFull--e0609";
export var heroImageContainerHidden = "other_hero-module--heroImageContainerHidden--93ce5";
export var heroTextContainer = "other_hero-module--heroTextContainer--979ba";
export var image = "other_hero-module--image--b375f";
export var imageFull = "other_hero-module--imageFull--b6248";
export var imageHidden = "other_hero-module--imageHidden--b94a7";
export var subtitle = "other_hero-module--subtitle--98b21";
export var tag = "other_hero-module--tag--64754";
export var tagContainer = "other_hero-module--tagContainer--a0ec9";
export var title = "other_hero-module--title--d2ffa";